import { mesDataService } from "@/services/mesDataService";
import { mutations as commonMutations } from "./components/v-common.js";


const machine = {
    namespaced: true,
    state: {
        data: [],      
        dataByProject: [],
    },
    getters: {
        machinePmData: (state) => {
           
            return state.dataByProject.filter(obj => obj.active === 1)
        },      

        dataActived: (state) => {

            return state.data.filter(obj => obj.active == 1)
        }
    },
    mutations: {     
        ...commonMutations,

        LIST_MACHINE_BY_PROJECT(state, payload) {
            payload ? state.dataByProject = payload : []
        },
        UPDATE_MACHINE_BY_PROJECT(state, payload) {
            const newData = state.dataByProject.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.dataByProject = newData
        }
    },
    actions: {
        

        async update({ dispatch, commit, rootState }, payload) {

            const uid = rootState.authen.user.email
            payload.updatedBy = uid;

            try {
                await mesDataService.machine.update(payload.id, payload)
                const resultObj = await mesDataService.machine.findOne(payload.id)
                commit('UPDATE_ITEM', resultObj.data)
               
                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async updatePMStatus({ dispatch, commit }, payload) {
           
            try {
                await mesDataService.machine.update(payload.id, payload)
                commit('UPDATE_MACHINE_BY_PROJECT', payload)
              
                dispatch('utils/alertUpdated', null, { root: true })

                return payload
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        

        async list({ dispatch }) {

            try {
                const result = await mesDataService.machine.list();
                                                     
                return result.data              

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

            return
        },

        async machineList({ dispatch }, projectId) {

            try {
                const result = await mesDataService.machine.machineList(projectId);
               
               return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

            return

        },

        
        async findByDepartment({ dispatch }, id) {

            try {
                
                const result = await mesDataService.machine.findByDepartment(id);
                
                return result.data               

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
           
        },

              
        async listByProjectArray({ dispatch, commit }, projectId) {
            try {
                const result = await mesDataService.machine.listByProjectArray(projectId);
                commit('LIST_MACHINE_BY_PROJECT', result.data)
                                                 
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async findOne({ dispatch,commit }, id) {
            try {
                const result = await mesDataService.machine.findOne(id);
                commit('UPSERT_ITEM', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async findLimit({ dispatch,commit }, jsonObj) { 
            try {
                
                const result = await mesDataService.machine.findLimit(jsonObj);   
                commit('BULK_ITEMS', result.data.data)   
                
                return result.data              

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }       
        }

    },
}

export { machine }