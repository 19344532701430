import { mesDataService } from "@/services/mesDataService";
import { mutations } from "./components/v-common";

const project = {
    namespaced: true,
    state: {
        data: []
    },
    getters: {   
    },
    mutations,
    actions: {

        async create({ dispatch, commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;
                const result = await mesDataService.project.create(payload)
                const resultObj = await mesDataService.project.findOne(result.data.id)
                commit('CREATE_ITEM', resultObj.data)
                dispatch('utils/alertAdded', null, { root: true })

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, commit, rootState }, payload) {

            const uid = rootState.authen.user.email;
            payload.updatedBy = uid;

            try {
                await mesDataService.project.update(payload.id, payload)
                const resultObj = await mesDataService.project.findOne(payload.id)
                commit('UPDATE_ITEM', resultObj.data)

                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })

            }
        },

        async destroy({ dispatch, commit }, id) {
            try {
                await mesDataService.project.destroy(id)
                commit('DELETE_ITEM', id)

                return 1
            } catch (e) {

                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async list({ dispatch, commit }) {
            try {
                const result = await mesDataService.project.list();
                commit('BULK_ITEMS', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


        async findByDepartmentId({ dispatch }, departmentId) {
            try {

                const result = await mesDataService.project.findByDepartmentId(departmentId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async findByPk({ dispatch }, projectId) {
            try {

                const result = await mesDataService.project.findByPk(projectId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async findLimit({ dispatch,commit }, jsonObj) { 
            try {
                
                const result = await mesDataService.project.findLimit(jsonObj);   
                commit('BULK_ITEMS', result.data.data)   
                
                return result.data              

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }       
        }



    },


}

export { project }