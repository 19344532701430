import { mesDataService } from "@/services/mesDataService"

const trcloud = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {

    },
    mutations: {
        LIST_DATA(state, payload) {
            state.data = payload

        },
        SET_DATA(state, payload) {

            if (!state.data.some(obj => obj.product_id === payload.product_id)) {
                state.data.splice(0, 0, payload)
            }
        },
    },
    actions: {

        async searchInventory({ dispatch, commit }, payload) {
            try {
               
                const result = await mesDataService.trcloud.searchInventory(JSON.stringify(payload))
                console.log(result)

                if (result) {
                    commit('LIST_DATA', result.data.result)
                    return result.data.result
                } else {
                    return null
                }

            } catch (e) {

                dispatch('utils/handleHttpError', e, { root: true })
            }
        },


        async retrieveInventory(_, payload) {
                                
            const result = await mesDataService.trcloud.retrieveInventory(JSON.stringify(payload))

            console.log(result)

            return result.data.result
        },

        async createMR(_, payload) { 

            try {
                             
                const result = await mesDataService.trcloud.createMR(payload)
           
                return result
            }catch(e) {
                return e
            }              
                
        },

        async searchMR(_, payload) {                      
          
            const result = await mesDataService.trcloud.searchMR(JSON.stringify(payload))

            return result.data.result
        },

    }
}

export { trcloud }