import { mesDataService } from "@/services/mesDataService";
import { mutations } from "./components/v-common";

const executor = {
    namespaced: true,
    state: {
        data: []
    },
    getters: {      

        dataActived: (state) => {

            return state.data.filter(obj => obj.active == 1)
        }        
    },
    mutations,
    actions: {
        async create({ dispatch, commit, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email;

                const result = await mesDataService.executor.create(payload)

                const resultObj = await mesDataService.executor.findOne(result.data.id)
                commit('CREATE_ITEM', resultObj.data)
             
                return
            } catch (e) {
                
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, commit, rootState }, payload) {

            const uid = rootState.authen.user.email

            payload.updatedBy = uid;

            try {
                await mesDataService.executor.update(payload.id, payload)           
                const resultObj = await mesDataService.executor.findOne(payload.id)
                commit('UPDATE_ITEM', resultObj.data)

                return
            } catch (e) {

                dispatch('utils/handleHttpError', e, { root: true })

            }

        },

        async destroy({dispatch, commit}, id) {
            try {
                await mesDataService.executor.destroy(id) 
                             
                commit('DELETE_ITEM', id)
 
                return  1           
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },

        async list({ dispatch, commit }) {

            try {

                const result = await mesDataService.executor.list();                
                commit('BULK_ITEMS', result.data)     
                
                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

        },

        async findByDepartment({ dispatch }, id) {

            try {
                
                const result = await mesDataService.executor.findByDepartment(id);
                
                return result.data               

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
           
        },

        async findLimit({ dispatch,commit }, jsonObj) { 
            try {
                
                const result = await mesDataService.executor.findLimit(jsonObj);              
                commit('BULK_ITEMS', result.data.data)   
                
                return result.data              

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }       
        },

        async findOne({ dispatch,commit }, id) {
            try {
                const result = await mesDataService.executor.findOne(id);
                console.log(result.data)
                commit('UPSERT_ITEM', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        
    }

}

export { executor }