import { mesDataService } from "@/services/mesDataService";

const cmTechnician = {
  namespaced: true,
  state: {
    data: [],
  },
  getters: {},

  mutations: {
    LIST_DATA(state, payload) {
      payload ? (state.data = payload) : "";
    },

    SET_DATA(state, payload) {
      if (!state.data.some((obj) => obj.id === payload.id)) {
        state.data.splice(0, 0, payload);
      }
    },
    UPDATE_DATA(state, payload) {
      const newData = state.data.map((el) =>
        el.id === payload.id ? Object.assign(el, payload) : el
      );
      state.data = newData;
    },
    DEL_DATA(state, payload) {
      state.data = state.data.filter((obj) => obj.id !== payload);
    },
  },
  actions: {
    async create({ dispatch, rootState, commit }, payload) {
      try {
        payload.updatedBy = rootState.authen.user.email;
        payload.createBy = rootState.authen.user.email;
        const result = await mesDataService.cmTechnician.create(payload);

        commit("SET_DATA", result.data);
        dispatch("utils/alertAdded", null, { root: true });

        return result.data;
      } catch (e) {
        dispatch("utils/handleHttpError", e, { root: true });
      }
    },

    async list({ dispatch, commit }) {
      try {
        const result = await mesDataService.cmTechnician.list();

        commit("LIST_DATA", result.data);

        return result.data;
      } catch (e) {
        dispatch("utils/handleHttpError", e, { root: true });
      }
    },

    async update({ dispatch, rootState }, payload) {
      try {
        payload.updatedBy = rootState.authen.user.email;

        await mesDataService.cmTechnician.update(payload.id, payload);

        dispatch("list");
        dispatch("utils/alertUpdated", null, { root: true });

        return;
      } catch (e) {
        dispatch("utils/handleHttpError", e, { root: true });
      }
    },

    async destroy({ dispatch, commit }, id) {
      try {
        await mesDataService.cmTechnician.destroy(id);
        commit("DEL_DATA", id);
        dispatch("utils/alertDeleted", null, { root: true });
        
        return 1;
      } catch (e) {
        dispatch("utils/handleHttpError", e, { root: true });
        return 0;
      }
    },
  },
};

export { cmTechnician };
