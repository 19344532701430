
const mutations = {
    CREATE_ITEM(state, item) {
        // Add a new item to the state
        state.data.unshift(item);
    },
    
    UPDATE_ITEM(state, updatedItem) {
        state.data = state.data.map(item =>
           // item.id === updatedItem.id ? { ...item, ...updatedItem } : item  
           item.id === updatedItem.id ? updatedItem : item
        );
    },
    UPSERT_ITEM(state, item) {
        const index = state.data.findIndex(existingItem => existingItem.id === item.id);

        if (index !== -1) {
            // Replace the item if the id exists          
            state.data.splice(index, 1, item);
        } else {
            // Add the item to the beginning if the id does not exist
            state.data.unshift(item);
        }
    },
    DELETE_ITEM(state, id) {
        // Remove the item with the given ID
        state.data = state.data.filter(obj => obj.id !== id);
    },

    BULK_ITEMS(state, items) {
        // Create a map of items by their ID for quick lookup
        const itemMap = new Map(items.map(item => [item.id, item]));

        // Update existing items or add new items from the incoming `items` array
        state.data = state.data.map(existingItem => {
            if (itemMap.has(existingItem.id)) {
                // Merge and update the existing item if a matching item is found
                return { ...existingItem, ...itemMap.get(existingItem.id) };
            }
            return existingItem;
        });

        // Add new items that don't exist in the state data
        const newItems = items.filter(item => !state.data.some(existingItem => existingItem.id === item.id));
        state.data = [...state.data, ...newItems];       
    }
}




export { mutations };


