import { mesDataService } from "@/services/mesDataService";


const cmTicket = {
  namespaced: true,
  state: {
    data: [],
  },
  getters: {},
  
  mutations: {

    LIST_DATA(state, payload) {
      payload ? (state.data = payload) : "";
    },
    
    SET_DATA(state, payload) {

      if (!state.data.some((obj) => obj.id === payload.id)) {
        state.data.splice(0, 0, payload);
      }
    }
  },
  actions: {

    async create({ dispatch, rootState, commit }, payload) {
      try {
        payload.updatedBy = rootState.authen.user.email;
        const result = await mesDataService.cmTicket.create(payload);

        commit("SET_DATA", result.data);
        dispatch("utils/alertAdded", null, { root: true });
        return result.data;
      } catch (e) {
        dispatch("utils/handleHttpError", e, { root: true });
      }
    },
    async update({ dispatch, rootState }, payload) {
      try {
        payload.updatedBy = rootState.authen.user.email;
        await mesDataService.cmTicket.update(payload.id, payload);
        dispatch("utils/alertUpdated", null, { root: true });
        return;
      } catch (e) {
        console.log(e);
        dispatch("utils/handleHttpError", e, { root: true });
      }
    },

    async findById({ dispatch, commit },payload) {
      try {

        const result = await mesDataService.cmTicket.findById(payload);

        commit("LIST_DATA", result.data[0]);
        return result.data[0];
      } catch (e) {
        dispatch("utils/handleHttpError", e, { root: true });
      }
    },

    async findBymachineId({ dispatch, commit },payload) {
      try {

        const result = await mesDataService.cmTicket.findBymachineId(payload);

        commit("LIST_DATA", result.data);
        return result.data;
      } catch (e) {
        dispatch("utils/handleHttpError", e, { root: true });
      }
    },

    async list({ dispatch, commit }) {
      try {
        const result = await mesDataService.cmTicket.list();

        commit("LIST_DATA", result.data);
        return result.data;
      } catch (e) {
        dispatch("utils/handleHttpError", e, { root: true });
      }
    },
  },
};

export { cmTicket };
