import { mesDataService } from "@/services/mesDataService"

const fileServer = {
    namespaced: true,
    state: {     
    },
    getters: {
    },
    mutations: {      
    },
    actions: {

        async upload({dispatch}, payload) {
            try {
                const result = await mesDataService.fileServer.upload(payload)
                dispatch('utils/alertAdded',null, {root: true})
                return result.data
            }catch (e) {
                const itemValue = payload.get('item');
                if (itemValue) {
                    const item = JSON.parse(itemValue);
                    if (item && item.noalert) {
                        return { message: e.message };
                    }
                }
                dispatch('utils/handleHttpError', e, { root: true });
            }
        },

        async download({ dispatch }, payload) {
            try {
                const result = await mesDataService.fileServer.download(payload)
                if (result.data.error) {
                    console.error(result.data.error)
                }
                
                return result.data
            } catch (e) {
                const obj = JSON.parse(payload);
                if (obj.noalert) {
                    return {message: e.message}
                }else{
                    dispatch('utils/handleHttpError', e, { root: true });
                }            

            }
        }

    }
}

export { fileServer }